import { jsPDF } from "jspdf";
import QRCode from "qrcode";

import { imgEncabezado2 } from "../../../helpers/imgBase64.js";

export const generateAcces = async (datos) => {
  try {
    // Crear el documento PDF
    const doc = new jsPDF("p", "pt", "letter");
    var startY = 20;

    doc.addImage(imgEncabezado2, "JPEG", 40, (startY += 10), 230, 60);

    // var img2 = new Image();
    // img2.src = imagenLogoAMLS;
    // var imgWidth = 115;
    // var imgHeight = 60;
    // doc.addImage(img2, "JPEG", 470, 10, imgWidth, imgHeight);

    doc.setFontSize(11);

    const imgWidthImg = 200; // Ancho de cada imagen
    const imgHeightImg = 330; // Alto de cada imagen
    const x1 = 80; // Coordenada x de la primera imagen
    const x2 = 300; // Coordenada x de la segunda imagen
    const y = 180; // Coordenada y para ambas imágenes

    //let imgFooter = new Image();
    //imgFooter.src = imagenFooter;
    //doc.addImage(imgFooter, 5, 720, 600, 65);

    // Generar código QR y verificar que se genere correctamente
    const qrText = datos._id; // Reemplaza con el ID real
    const qrCodeDataURL = await QRCode.toDataURL(qrText, { width: 228 });
    doc.setFont("Helvetica", "bold");

    const pageWidth = doc.internal.pageSize.getWidth();
    const margin = 20;

    // Título con fuente más grande y centrado
    doc.setFontSize(18);
    doc.text("Solicitud única de acceso", pageWidth / 2, 170, {
      align: "center",
    });

    // Folio
    doc.setFontSize(14);
    doc.text(
      formatFolio(datos.folio, datos.muelle, datos.createdAt),
      pageWidth / 2,
      195,
      {
        align: "center",
      }
    );

    // Embarcación, Muelle, Agencia
    doc.setFontSize(10);
    doc.setFont("Helvetica", "bold");

    doc.text("Embarcación: " + datos.embarcacion, pageWidth / 2, 380, {
      align: "center",
    });
    doc.text("Muelle: " + datos.muelle, pageWidth / 2, 405, {
      align: "center",
    });
    doc.text("Agencia: " + datos.agencia, pageWidth / 2, 430, {
      align: "center",
    });

    // Añadir la imagen del código QR (centrada)
    if (qrCodeDataURL) {
      const qrWidth = 150; // Ancho del QR
      const qrHeight = 150; // Altura del QR
      const qrXPosition = (pageWidth - qrWidth) / 2; // Posiciona el QR en el centro horizontal
      const qrYPosition = 200; // Posición vertical más baja

      doc.addImage(
        qrCodeDataURL,
        "PNG",
        qrXPosition,
        qrYPosition,
        qrWidth,
        qrHeight
      );
    } else {
      console.error("Error al generar el código QR");
    }

    // Texto adicional en la parte inferior
    doc.setFontSize(7);
    doc.setFont("Helvetica", "normal");

    const bottomText =
      "Este pase de acceso es único y se proporciona exclusivamente a la agencia responsable. " +
      "Bajo ninguna circunstancia puede ser utilizado más de una vez, ya que está protegido contra usos no autorizados. " +
      "El acceso estará sujeto a las normas y condiciones establecidas por la administración del puerto y cualquier intento de reutilización será invalidado.\n\n" +
      "Se le recuerda a la agencia que toda la información proporcionada debe ser verídica. Cualquier discrepancia o mal uso será reportado a las autoridades pertinentes.";
    doc.text(bottomText, margin, 680, {
      maxWidth: pageWidth - 2 * margin,
      align: "justify",
    });

    // Guardar el documento PDF
    // Devolver el PDF como un buffer en formato arraybuffer
    const pdfOutput = doc.output("blob");

    // Create a URL for the Blob
    const pdfUrl = URL.createObjectURL(pdfOutput);

    // Open the PDF preview in a new window
    window.open(pdfUrl, "_blank");
  } catch (error) {
    console.error("Error generando el PDF:", error);
    throw new Error("No se pudo generar el PDF");
  }
};

const formatFolio = (folio, muelle, created) => {
  const folio_number = folio.toString().padStart(4, "0");
  const date = new Date(created);
  const day = date.getUTCDate().toString().padStart(2, "0"); // Asegura dos dígitos
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  let muelle_folio = "";
  if (
    muelle == "CHILTEPEC" ||
    muelle == "MUELLE DE TANQUES" ||
    muelle == "MUELLE PESCADORES CHILTEPEC"
  ) {
    muelle_folio = "CH";
  } else {
    muelle_folio = "FR";
  }
  let clave_muelle = "";
  switch (muelle) {
    case "ESPADAÑAL":
      clave_muelle = "PAR";
      break;
    case "CENTRO DE REPARACIONES NAVALES":
      clave_muelle = "PAR";
      break;
    case "MUELLE PESCADORES FRONTERA":
      clave_muelle = "DEP";
      break;
    case "MUELLE FISCAL":
      clave_muelle = "FIS";
      break;
    case "PARTICULAR":
      clave_muelle = "PAR";
      break;
    case "MUELLE DE TANQUES":
      clave_muelle = "PAR";
      break;
    case "MUELLE PESCADORES CHILTEPEC":
      clave_muelle = "PES";
      break;
    case "SANCHEZ MAGALLANES":
      clave_muelle = "PAR";
      break;
    case "MUELLE DE TANQUES":
      clave_muelle = "PAR";
      break;
    case "MUELLE COSUGO":
      clave_muelle = "PAR";
      break;
    case "MUELLE PRIVADO CARLOS DAVID":
      clave_muelle = "PAR";
      break;
  }

  if (folio <= 75) {
    return folio_number;
  }
  let otro_folio = folio_number - 75;
  const folio_number_2 = otro_folio.toString().padStart(4, "0");
  return `${folio_number_2}/${muelle_folio}/${clave_muelle}/${day}${month}${year}`;
};
